import { issuerImages } from './credit-card-payments';

export const paymentsMethods = [
  {
    name: 'Boleto Bancário',
    smartImage: { url: '/images/boleto.png' },
    smartImageProps: {
      resolutionSizes: { url: "/images/boleto@2x.png' 2x, /images/boleto.png 1x" },
    },
    imageProps: { width: 44, height: 33, alt: 'Boleto Bancário', title: 'Boleto Bancário' },
  },
  ...issuerImages,
];
