import styled from '@emotion/styled';
import {
  Box,
  Container,
  Divider,
  Link,
  Typography,
  TypographyProps,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { OptimizedImage } from '@web/common/components/optimized-image';
import { PaymentMethodsSection } from '@web/home/components/payment-methods-section';
import format from 'date-fns/format';
import NextLink from 'next/link';
import { FC } from 'react';
import { blogUrl, instagramUrl } from '../constants/external-urls';

const FooterContainer = styled.footer`
  position: relative;
  background-color: white;
  box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.3);
  padding-top: ${props => props.theme.spacing(2)};
  padding-bottom: ${props => props.theme.spacing(8)};
  text-align: center;
`;

const FooterSocialLinkContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: ${props => props.theme.spacing(1, 0)};
`;

const FooterSubtitleText = styled(Typography)`
  margin: ${props => props.theme.spacing(2, 0)};
`;

const FooterLinkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => props.theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`;

const FooterLink = styled.div`
  margin: ${props => props.theme.spacing(1, 2)};
`;

const FooterIcon = styled.div`
  margin: ${props => props.theme.spacing(1, 2)};
  opacity: 0.8;
`;

const sacTextInfo: TypographyProps = {
  variant: 'body1',
  color: 'textSecondary',
  gutterBottom: true,
};

export const Footer: FC = () => {
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <FooterContainer>
      <Container maxWidth="md">
        <Box mt={1} mb={4}>
          <OptimizedImage
            src="/images/logo-veroo.svg"
            ext="svg"
            height={downSm ? '60' : '81'}
            lazy
            lazyHeight={downSm ? 56 : 81}
            alt="Veroo cafés logomarca"
            title="Veroo cafés logomarca"
          />
        </Box>
        <FooterLinkContainer>
          <FooterLink>
            <NextLink href="/" passHref>
              <Link underline="none" title="Inicio">
                <Typography variant="body1" color="textSecondary">
                  Início
                </Typography>
              </Link>
            </NextLink>
          </FooterLink>
          <FooterLink>
            <NextLink href="/loja" passHref>
              <Link underline="none" title="Loja">
                <Typography variant="body1" color="textSecondary">
                  Loja
                </Typography>
              </Link>
            </NextLink>
          </FooterLink>
          <FooterLink>
            <NextLink href="/produtores" passHref>
              <Link title="Produtores" underline="none">
                <Typography variant="body1" color="textSecondary">
                  Produtores
                </Typography>
              </Link>
            </NextLink>
          </FooterLink>
          <FooterLink>
            <Link underline="none" href={blogUrl} title="Blog">
              <Typography variant="body1" color="textSecondary">
                Blog
              </Typography>
            </Link>
          </FooterLink>
          <FooterLink>
            <Link
              underline="none"
              href="https://veroo.cafe/contato"
              target="_blank"
              title="Inicio"
              rel="nofollow noreferrer"
            >
              <Typography variant="body1" color="textSecondary">
                Contato
              </Typography>
            </Link>
          </FooterLink>
        </FooterLinkContainer>
        <FooterSocialLinkContainer>
          <FooterIcon>
            <Link
              underline="none"
              href="https://www.facebook.com/veroocafes/"
              rel="nofollow"
              title="Veroo no Facebook"
            >
              <OptimizedImage
                src="/icons/facebook.svg"
                ext="svg"
                lazy
                width="24"
                height="24"
                alt="Veroo facebook"
                title="Veroo facebook"
              />
            </Link>
          </FooterIcon>
          <FooterIcon>
            <Link underline="none" href={instagramUrl} rel="nofollow" title="Veroo no Instagram">
              <OptimizedImage
                src="/icons/instagram.svg"
                ext="svg"
                lazy
                width="24"
                height="24"
                alt="Veroo instagram"
                title="Veroo instagram"
              />
            </Link>
          </FooterIcon>
        </FooterSocialLinkContainer>
        <Divider />
        <FooterSubtitleText variant="h6" {...{ component: 'p' }}>
          Pagamento
        </FooterSubtitleText>
        <OptimizedImage
          src="/images/logo-pagarme@2x.png"
          height="60"
          ext="png"
          lazy
          lazyHeight={80}
          alt="Pagarme"
          title="Logo Pagarme"
        />
        <PaymentMethodsSection />
        <Divider />
        <FooterSubtitleText variant="h6" {...{ component: 'p' }}>
          Segurança
        </FooterSubtitleText>
        <FooterLinkContainer>
          <Box mb={2} mr={3}>
            <Link
              underline="none"
              href="https://transparencyreport.google.com/safe-browsing/search?url=https:%2F%2Fveroo.com.br%2F&hl=pt_BR"
              target="_blank"
              rel="nofollow noreferrer"
              title="Google site seguro"
            >
              <OptimizedImage
                src="/images/google-badge.png"
                height="56"
                alt="Google site seguro"
                lazy
                lazyHeight={56}
              />
            </Link>
          </Box>
        </FooterLinkContainer>
        <Divider />
        <FooterSubtitleText variant="h6" {...{ component: 'p' }}>
          Sac
        </FooterSubtitleText>
        <Typography {...sacTextInfo}>
          <strong>+55 16 99305-0398</strong>
        </Typography>
        <Typography {...sacTextInfo}>
          <strong>sac@veroo.com.br</strong>
        </Typography>
        <Typography {...sacTextInfo} paragraph>
          segunda a sexta das 9h às 17:00h
        </Typography>
        <Divider />
        <Box mt={2} mb={4}>
          <Typography variant="body2" color="textSecondary">
            Copyright {format(new Date(), 'yyyy')} © Veroo Cafés Especiais{' | '}
            <NextLink href="/politica-de-privacidade" passHref>
              <Link underline="none">Política de Privacidade</Link>
            </NextLink>
            {' | '}
            <NextLink href="/politica-de-cookies" passHref>
              <Link underline="none">Política de Cookies</Link>
            </NextLink>
            {' | '}
            <NextLink href="/termos-de-uso" passHref>
              <Link underline="none">Termos de Uso</Link>
            </NextLink>
            {' | '}Todos os direitos reservados.
          </Typography>
          <Typography variant="body2" color="textSecondary">
            CNPJ: 31.509.062/0001-57
            {' | '}Razão Social: Coferoo Conexões e Negócios LTDA
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Website criado com <span style={{ color: 'red' }}>♥</span> por{' '}
            <Link underline="none" href="https://blb.ventures" rel="nofollow noreferrer">
              BLBVentures
            </Link>
          </Typography>
        </Box>
      </Container>
    </FooterContainer>
  );
};

export default Footer;
