import { paymentsMethods } from '@web/common/constants/payment-methods';
import styled from '@emotion/styled';
import { FC } from 'react';
import { SmartImage } from '@web/common/smart-image';

const FooterPaymentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: ${props => props.theme.spacing(1, 0, 2)};
`;

const IssuerSmartImage = styled(SmartImage)`
  img {
    max-width: 100%;
    height: auto;
  }
`;

export const PaymentMethodsSection: FC = () => (
  <FooterPaymentContainer>
    {paymentsMethods.map(payment => (
      <IssuerSmartImage
        key={payment.name}
        image={payment.smartImage}
        {...payment.smartImageProps}
      />
    ))}
  </FooterPaymentContainer>
);
