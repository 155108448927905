import { OptimizedImageProps } from '../components/optimized-image';
import { SmartImageProps, SmartImageType } from '../smart-image';

export interface IssuerImgProps {
  name: string;
  imgProps?: Partial<OptimizedImageProps>;
  smartImage: SmartImageType;
  smartImageProps: Partial<SmartImageProps>;
}

export const issuerImages: IssuerImgProps[] = [
  {
    name: 'American Express',
    smartImage: { url: '/images/card-flags/amex.png' },
    smartImageProps: {
      resolutionSizes: { url: '/images/card-flags/amex@2x.png 2x, /images/card-flags/amex.png 1x' },
      imageProps: {
        width: 70,
        height: 33,
        alt: 'Bandeira American express',
        title: 'American express',
      },
    },
  },
  {
    name: 'Elo',
    smartImage: { url: '/images/card-flags/elo.png' },
    smartImageProps: {
      resolutionSizes: { url: '/images/card-flags/elo@2x.png 2x, /images/card-flags/elo.png 1x' },
      imageProps: {
        width: 70,
        height: 33,
        alt: 'Bandeira Elo',
        title: 'Elo',
      },
    },
  },
  {
    name: 'Master Card',
    smartImage: { url: '/images/card-flags/master.png' },
    smartImageProps: {
      resolutionSizes: {
        url: '/images/card-flags/master@2x.png 2x, /images/card-flags/master.png 1x',
      },
      imageProps: {
        width: 70,
        height: 33,
        alt: 'Bandeira Master Card',
        title: 'Master Card',
      },
    },
  },
  {
    name: 'Visa',
    smartImage: { url: '/images/card-flags/visa.png' },
    smartImageProps: {
      resolutionSizes: { url: '/images/card-flags/visa@2x.png 2x, /images/card-flags/visa.png 1x' },
      imageProps: {
        width: 70,
        height: 33,
        alt: 'Bandeira Visa',
        title: 'Visa',
      },
    },
  },
  {
    name: 'Diners Club',
    smartImage: { url: '/images/card-flags/diners-club.png' },
    smartImageProps: {
      resolutionSizes: {
        url: '/images/card-flags/diners-club@2x.png 2x, /images/card-flags/diners-club.png 1x',
      },
      imageProps: {
        width: 70,
        height: 33,
        alt: 'Bandeira Diners Club',
        title: 'Diners Club',
      },
    },
  },
  {
    name: 'Hipercard',
    smartImage: { url: '/images/card-flags/hipercard.png' },
    smartImageProps: {
      resolutionSizes: {
        url: '/images/card-flags/hipercard@2x.png 2x, /images/card-flags/hipercard.png 1x',
      },
      imageProps: {
        width: 70,
        height: 33,
        alt: 'Bandeira Hipercard',
        title: 'Hipercard',
      },
    },
  },
  {
    name: 'Jcb',
    smartImage: { url: '/images/card-flags/jcb.png' },
    smartImageProps: {
      resolutionSizes: { url: '/images/card-flags/jcb@2x.png 2x, /images/card-flags/jcb.png 1x' },
      imageProps: {
        width: 70,
        height: 33,
        alt: 'Bandeira Jcb',
        title: 'Jcb',
      },
    },
  },
  {
    name: 'Discover',
    smartImage: { url: '/images/card-flags/discover.png' },
    smartImageProps: {
      resolutionSizes: {
        url: '/images/card-flags/discover@2x.png 2x, /images/card-flags/discover.png 1x',
      },
      imageProps: {
        width: 70,
        height: 33,
        alt: 'Bandeira Discover',
        title: 'Discover',
      },
    },
  },
];
